import React, { useEffect, useState } from "react";
import { useNavigate,Outlet } from "react-router-dom";
import { Search, BookOpen, X } from "lucide-react";

const StudyMaterial = () => {
  // const api = "http://localhost:8000/api/admin/";
  const api="https://skillyards.com/api/admin/"
  const [topics, setTopics] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const getCourses = async () => {
    try {
      setLoading(true);
      let response = await fetch(`${api}getAllCourses`);
      response = await response.json();
      if (response.error === false) {
        setTopics(response.courseSections);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCourses();
  }, []);
  
  

  const handleSearch = (e) => {
   setSearchQuery(e.target.value)
    
    if (searchQuery.trim()) {
      setIsSearching(true);
      const results = topics.filter(
        topic =>
          topic.subject.toLowerCase().includes(searchQuery.toLowerCase()) ||
          topic.description.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setSearchResults(results);
    } else {
      setIsSearching(false);
      setSearchResults([]);
    }
  };

  const clearSearch = () => {
    setSearchQuery("");
    setIsSearching(false);
    setSearchResults([]);
  };

  const getGradientClass = (index) => {
    const gradients = [
      "from-purple-500 to-indigo-600",
      "from-blue-500 to-teal-400",
      "from-emerald-500 to-teal-400",
      "from-orange-500 to-pink-500"
    ];
    return gradients[index % gradients.length];
  };

  
  

  // Search Results Component
  const SearchResults = () => (
    <div className="mt-8">
      {searchResults.length > 0 ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {searchResults.map((result, index) => (
            <div
              key={result._id}
              className={`p-6 rounded-xl shadow-lg bg-gradient-to-br ${getGradientClass(index)} 
                transform hover:scale-105 transition-all duration-300 cursor-pointer`}
              onClick={() => navigate(`/study/${result?.subject}`)}
            >
              <h3 className="text-xl font-bold text-white mb-2">{result.subject}</h3>
              <p className="text-white/90 text-sm">{result.description}</p>
              <p className="text-white/80 text-xs mt-2">{result.usedInIndustries}</p>
            </div>
          ))}
        </div>
      ) : (
        <div className="text-center py-12">
          <BookOpen className="mx-auto text-gray-400 mb-4 h-16 w-16" />
          <h3 className="text-xl font-semibold text-gray-700 mb-2">No Results Found</h3>
          <p className="text-gray-500">
            Try adjusting your search terms or browse all materials
          </p>
        </div>
      )}
    </div>
  );

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="container mx-auto px-4 py-12">
        {/* Header Section */}
        <div className="text-center mb-12">
          <h1 className="text-4xl md:text-5xl font-bold text-gray-900 mb-4">
            Study Material
          </h1>
          <p className="text-lg text-gray-600 max-w-2xl mx-auto">
            Explore our comprehensive collection of learning resources
          </p>
        </div>

        {/* Search Section */}
        <div className="max-w-2xl mx-auto mb-12">
          <div className="relative">
            <input
              type="text"
              placeholder="Search by topic or keyword..."
              value={searchQuery}
              onChange={
                (e)=>handleSearch(e)}
            
              className="w-full px-6 py-4 text-lg border-2 border-gray-200 rounded-full 
                focus:outline-none focus:border-blue-500 transition-colors duration-300"
            />
            {searchQuery && (
              <button
                onClick={clearSearch}
                className="absolute right-16 top-1/2 transform -translate-y-1/2 
                  text-gray-400 hover:text-gray-600"
              >
                <X className="h-5 w-5" />
              </button>
            )}
            <button
              onClick={handleSearch}
              className="absolute right-4 top-1/2 transform -translate-y-1/2 
                text-blue-500 hover:text-blue-600"
            >
              <Search className="h-6 w-6" />
            </button>
          </div>
        </div>

        {/* Content Section */}
        {loading ? (
          <div className="text-center py-12">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500 mx-auto"></div>
          </div>
        ) : isSearching ||searchQuery.length ? (
          <SearchResults />
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
            {topics.map((topic, index) => (
              <div
                key={topic._id}
                className={`p-6 rounded-xl shadow-lg bg-gradient-to-br ${getGradientClass(index)}
                  transform hover:scale-105 transition-all duration-300 cursor-pointer`}
                onClick={() => navigate(`/study/${topic?.subject}`)}
              >
                <h3 className="text-xl font-bold text-white mb-2">{topic.subject}</h3>
                <p className="text-white/90 text-sm">{topic.description}</p>
                <p className="text-white/80 text-xs mt-2">{topic.usedInIndustries}</p>
              </div>
            ))}
          </div>
        )}
      </div>
       
    </div>
  );
};

export default StudyMaterial;