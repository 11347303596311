import React, { lazy, Suspense, startTransition } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import '../style/adminroutes.css';
import LoadingSpiner from '../Loading.jsx';
import AdminPost from './AdminStudyMaterial.jsx';


const Dashboard = lazy(() => import('./Dashboard.js'));
const StudentList = lazy(() => import('./StudentList.js'));
const AccountHistory = lazy(() => import('./AccountHistory.js'));
const CertificateList = lazy(() => import('./CertificateList.js'));
const Header = lazy(() => import('./AdminHeader.js'));
const Footer = lazy(() => import('./AdminFooter.js'));
const Sidebar = lazy(() => import('./Sidebar.js'));
const Contact = lazy(() => import('./ContactRequest.js'));
const Blog=lazy(()=>import('./Blog/AdminBlog.js'));
const AddBlog = lazy(()=>import('./Blog/AddBlog.js'));
const Uploadblog=lazy(()=>import('./Blog/UpdateBlog.js'));
const DigitaMarketingDashboard=lazy(()=>import("../Admin/digitalMarketing/DigitalMarketingDashboard.jsx"))

function AdminRoutes() {
  return (
 
      <div className="admin-panel">
        <Suspense fallback={<div>

          <LoadingSpiner/>
        </div>}>
          <Header />
          <div className="admin-main-content">
            <Sidebar />
            <div className="content-area">
            
                <Routes>
                  {/* <Route   exact={true}  path="/admin" element={<Login />} /> */}
                  <Route   exact={true} path="/dashboard" element={<Dashboard />} />
                  <Route   exact={true} path="/students" element={<StudentList />} />
                  <Route   exact={true} path="/account-history" element={<AccountHistory />} />
                  <Route   exact={true} path="/certificates" element={<CertificateList />} />
                  <Route   exact={true} path="/contact" element={<Contact />} />
                  <Route   exact={true} path="/blogs" element={<Blog />} />
                  <Route   exact={true} path="/add-blog" element={<AddBlog />} />
                  <Route   exact={true} path="/update-blog" element={<Uploadblog />} />
                  <Route exact={true} path='/study-add' element={<AdminPost/>}   /> 
                  <Route exact={true} path="/digitalmarketing-dashboard" element={<DigitaMarketingDashboard/>} />
                </Routes>
         
            </div>
          </div>
          <Footer />
        </Suspense>
      </div>
  
  );
}

export default AdminRoutes;
