import React, { useEffect } from "react";

const DownloadFiles = ({ fileUrl, fileName, target = "_self" }) => {
  useEffect(() => {
    if (fileUrl) {
      const link = document.createElement("a");
      link.href = fileUrl;
      link.download = fileName || fileUrl.split("/").pop(); // Use file name or extract from URL
      link.target = target; // Specify target (_self or _blank)
      link.click();
    }
  }, [fileUrl, fileName, target]);

  return null; // No UI is displayed
};

export default DownloadFiles;
