
import React, { useState } from "react";
import  ContentTable  from "./studymaterial/contentTable";
import FormComponent from "./studymaterial/ContentForm";

export default function AdminPost() {

  const api="https://www.skillyards.com/api/admin/"

  const [content,setContent] = useState([])

  
  

  const handelAddContent = (data) => {}
  const handelEditContent = (data) => {}
  const handelDelContent = (data) => {}

  const [createCourse, setCreateCourse] = useState({
    usedInIndustries:"",
    description:"",
    subject:""
  })
  const handeChangelAddCourse = (e) => {
    const {name, value} = e.target;
    setCreateCourse({...createCourse, [name]: value})
  }

  const handelCreateCourse = async (e) => {
    
    e.preventDefault();

     
    // console.log('api called');
     
    try {
      let response = await fetch(`${api}createCourse`,{
        method:"POST",
        headers:{"Content-Type":"application/json"},
        body:JSON.stringify(createCourse)
      })

     response=await response.json()
     console.log(response);
     alert('course created success')
     
    
   } catch (error) {
  
      console.error("Error posting data:", error);
      alert("An error occurred. Please try again.");
   }


}

 

const [formData, setFormData] = useState({
  title: "",            
  description: "",       
  topic: "",             
  subTopic: "",          
  subDescription: "",    
  example: "",         
  codeSnippet: "",      
  file: null,            
  links: [],             
  prerequisites: [],     
  subject: "",        
  tags: [],              
  level: "",             
  duration: "",       
  courseId: "",          
});


  
  

 
  

  return (
    <div className="flex flex-col justify-center items-center min-h-screen bg-gradient-to-r from-blue-50 to-gray-100 p-2">
    
    <h2 className="text-2xl font-semibold text-center mb-6 text-gray-800">
  Create a Course
</h2>
<form
  onSubmit={handelCreateCourse}
  className=" flex  justify-evenly items-center gap-2 border p-4"
>
  {/* Subject */}
  <div>
    <label
      htmlFor="subject"
      className="block text-gray-700 font-medium mb-1"
    >
      Course Name
    </label>
    <input
      type="text"
      id="subject"
      name="subject"
      placeholder="Enter course name"
      onChange={handeChangelAddCourse}
      value={createCourse.subject}
      className="w-full border rounded-lg px-4 py-2 mt-1 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
      required
    />
  </div>

  {/* Title */}
  <div>
    <label htmlFor="title" className="block text-gray-700 font-medium mb-1">
     Industrie Used
    </label>
    <input
      type="text"
      id="usedInIndustries"
      name="usedInIndustries"
      placeholder="Used In Industries"
      onChange={handeChangelAddCourse}
      value={createCourse.usedInIndustries}
      className="w-full border rounded-lg px-4 py-2 mt-1 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
      required
    />
  </div>

  {/* Description */}
  <div>
    <label
      htmlFor="description"
      className="block text-gray-700 font-medium mb-1"
    >
      Description
    </label>
    <input
      id="description"
      name="description"
      placeholder="Enter course description"
      onChange={handeChangelAddCourse}
      value={createCourse.description}
      rows="4"
      className="w-full border rounded-lg px-4 py-2 mt-1 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
      required
    ></input>
  </div>

  {/* Submit Button */}
  <button
    type="submit"
    className=" bg-gradient-to-r from-blue-500 to-blue-600 text-white rounded-lg py-3 font-semibold hover:from-blue-600 hover:to-blue-700 transition duration-300"
  >
    Submit
  </button>
</form>

   
    <FormComponent createCourse={createCourse} setCreateCourse={setCreateCourse} formData={formData} setFormData={setFormData} setContent={setContent} handelCreateCourse={handelCreateCourse}  />
     
     {content.length>0&& <ContentTable data={content}  />}
    
  </div>

  
  
  );
}


// import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
// import { dracula } from "react-syntax-highlighter/dist/esm/styles/prism";
// import {useState} from "react"

// const AdminPost = () => {
//   const [formData, setFormData] = useState({
//     courseSection: "",
//     description: "",
//     content: "",
//     file: null,
//   });

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prev) => ({ ...prev, [name]: value }));
//   };

//   const handleFileChange = (e) => {
//     const { files } = e.target;
//     setFormData((prev) => ({ ...prev, file: files[0] }));
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     // Handle form submission logic
//   };

//   const codeSnippet = `
// function greet(name) {
//   return \`Hello, \${name}!\`;
// }

// console.log(greet("World"));
//   `;

//   return (
//     <div className="flex justify-center items-center min-h-screen bg-gradient-to-r from-blue-50 to-gray-100 p-6">
//       <form
//         onSubmit={handleSubmit}
//         className="bg-white shadow-2xl rounded-2xl p-8 w-full max-w-lg space-y-6 transform hover:scale-105 transition duration-300"
//       >
//         <h2 className="text-3xl font-extrabold text-center text-gray-800 tracking-tight">
//           Post Course Content
//         </h2>

//         {/* Introductory Description */}
//         <div>
//           <p className="text-gray-600 text-lg">
//             This form allows you to post content for a course. You can choose a section, describe the topic,
//             write the content, and upload relevant files (images or PDFs). Please make sure the content is clear
//             and well-explained.
//           </p>
//         </div>

//         {/* Code Snippet and Explanation */}
//         <div className="space-y-4 mt-6">
//           <h3 className="text-2xl font-semibold text-gray-700">Example Code</h3>

//           <SyntaxHighlighter language="js" style={dracula}>
//             {codeSnippet}
//           </SyntaxHighlighter>

//           <p className="text-gray-600">
//             The above function is a simple JavaScript function called <code className="font-medium">greet</code>. 
//             It takes one argument, <code className="font-medium">name</code>, and returns a greeting message 
//             using template literals. The <code className="font-medium">console.log</code> outputs the greeting message 
//             to the console when you pass in a string such as <code className="font-medium">"World"</code>.
//           </p>
//         </div>

//         {/* Course Section */}
//         <div>
//           <label htmlFor="courseSection" className="block text-gray-700 font-medium mb-1">
//             Select Section
//           </label>
//           <select
//             id="courseSection"
//             name="courseSection"
//             value={formData.courseSection}
//             onChange={handleChange}
//             className="w-full border rounded-lg px-4 py-2 mt-1 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
//             required
//           >
//             <option value="" disabled>Select a section</option>
//             <option value="HTML">HTML</option>
//             <option value="CSS">CSS</option>
//             <option value="JavaScript">JavaScript</option>
//             <option value="React">React</option>
//           </select>
//         </div>

//         {/* Description */}
//         <div>
//           <label htmlFor="description" className="block text-gray-700 font-medium mb-1">
//             Description
//           </label>
//           <textarea
//             id="description"
//             name="description"
//             value={formData.description}
//             onChange={handleChange}
//             rows="3"
//             className="w-full border rounded-lg px-4 py-2 mt-1 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
//             placeholder="Add a brief description..."
//             required
//           ></textarea>
//         </div>

//         {/* Content */}
//         <div>
//           <label htmlFor="content" className="block text-gray-700 font-medium mb-1">
//             Content (Text)
//           </label>
//           <textarea
//             id="content"
//             name="content"
//             value={formData.content}
//             onChange={handleChange}
//             rows="5"
//             className="w-full border rounded-lg px-4 py-2 mt-1 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
//             placeholder="Write your course content here..."
//           ></textarea>
//         </div>

//         {/* File Upload */}
//         <div>
//           <label htmlFor="file" className="block text-gray-700 font-medium mb-1">
//             Upload File (Image or PDF)
//           </label>
//           <input
//             type="file"
//             id="file"
//             accept="image/*,application/pdf"
//             onChange={handleFileChange}
//             className="w-full border rounded-lg px-4 py-2 mt-1 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent bg-gray-50"
//           />
//         </div>

//         {/* Submit Button */}
//         <button
//           type="submit"
//           className="w-full bg-gradient-to-r from-blue-500 to-blue-600 text-white rounded-lg py-3 font-semibold hover:from-blue-600 hover:to-blue-700 transition duration-300"
//         >
//           Submit
//         </button>
//       </form>
//     </div>
//   );
// };

// export default AdminPost;

