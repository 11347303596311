import React, { useState, useEffect, useRef, Suspense,lazy, useCallback } from "react";


import award from "../../assets/digital_icon/award.webp"
import coin from "../../assets/digital_icon/coin.webp"
import computer from "../../assets/digital_icon/computer.webp"
import gift from "../../assets/digital_icon/gift.webp"
 import microchip from "../../assets/digital_icon/microchip.webp"
 import supriceBox from "../../assets/digital_icon/surprise-box.webp"

 import bg1 from "../../assets/digital_icon/ai_tool.webp"
 import bg2 from "../../assets/digital_icon/incorp.webp"
 import bg3 from "../../assets/digital_icon/certificate.webp"
 import bg4 from "../../assets/digital_icon/Top-Marketing.webp"
 import bg5 from "../../assets/digital_icon/bnous.webp"
 import bg6 from "../../assets/digital_icon/surprise.webp"
 import cr1 from "../../assets/digital_icon/certificate/1.webp"
 import cr2 from "../../assets/digital_icon/certificate/2.webp"
 import cr3 from "../../assets/digital_icon/certificate/3.webp"


import rupansh from "../../assets/digital_icon/rupansh.webp"




import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import {FileText} from "lucide-react"

const WhoCanJoin=lazy(()=>import("./whoCanJoin"))
const FlipCard = lazy(() => import("./flipingCard"));
const Carousel = lazy(() => import("./crousel"));
const LearnCard = lazy(() => import("./learncards"));
const TrainerCard = lazy(() => import("./trainerSection"));
const FAQComponent = lazy(() => import("./faqSection"));
const AnimatedCounter=lazy(()=>import("./numberSpinner"))
const HeroSection=lazy(()=>import('./herosection'))
const Join=lazy(()=>import("./join"))




const StatItem = ({ value, label,backValue }) => {
  
  
  return (

    <div className="bg-[#5E5F5E] w-full rounded-xl gap-3 lg:p-2 shadow-lg text-center p-0 m-0  lg:w-[30%]">
      <p className="text-3xl text-white font-bold m-2 p-0 ">{<AnimatedCounter  backValue={backValue} value={Number(value)} />}</p>
      <p className="text-xl text-white m-2 p-0">{label}</p>
    </div>
  );
};


const ConnectWithMarket = () => {
  const [fadeOut, setFadeOut] = useState(false);
  
  
    const images = [
        { titel:"Startup India",heading:"Skillyards Versatility is recognised by Startup India", src: cr2, alt: 'Image 1' },
        { titel:"Best Digital Marketing Agency",heading:"SN Digitech, Skilllyards’ Parent Company, was awarded One of the Best Digital Marketing Agencies in New York"
          ,src:cr1, alt: 'Image 2' },
        { titel:"MSME",heading:" Skillyards recognized by MSME India for training excellence.",src:cr3, alt: 'Image 2' },

      ];

    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSubmit = (e) => {
      e.preventDefault();
      setIsSubmitting(true);
      // Simulate form submission
      setTimeout(() => {
        setIsSubmitting(false);
      }, 2000);
    };

    const statsData = [
        { value: "15 +", label: "Years in Business" },
        { value: "300 +", label: "Happy Clients" },
        { value: "30 +", label: "Domain Expertise" },
        { value: "100 +", label: "Employees" },
        { value: "360°", label: "Integrated Marcom Agency" },
        { value: "98 %", label: "Campaign Success Rate" },
      ];
       
      const cardData=[{id:1,
        front:"AI Incorporation",
        back:"AI Incorporation",
        img:microchip,
        bgimg:bg1
      },{id:2,
        front:"AI Tools",
        back:"20+ Free AI Tools to maximise productivity in marketing",
        img:computer,
        bgimg:bg2
      },{id:3,
        front:"Certification",
        back:"Free Certification Upon Completing the Workshop",
        img:award,
        bgimg:bg3
      },{id:4,
        front:"Paid Tools for FREE",
        back:"Chance to get access to 5+ Paid Tools for Free",
        img:coin,
        bgimg:bg4
      },
      {id:5,
        front:"Bonus Templates",
        back:"Multiple Marketing Calendars to save time arranging everything",
        img:gift,
        bgimg:bg5
      },
      {id:6,
        front:"Surprise Bonuses",
        back:"Unique Surprise Bonuses for Top Participants",
        img:supriceBox,
        bgimg:bg6
      }]

      const learnCards = [
        {
          id: 1,
          title: "What is Digital Marketing?",
          des: [
            { id: 11, top: "What is Digital Marketing?" },
            { id: 222, top: "Scope of Digital Marketing" },
            { id: 333, top: "Introduction to Ads" },
            { id: 444, top: "Introduction to Tools" },
            { id: 555, top: "Introduction to Landing Pages" },
          ],
        },
        {
          id: 2,
          title: "Meta Ads Audit",
          des: [
            { id: 666, top: "Introduction to Meta Ads Auditing" },
            { id: 777, top: "Key Metrics for Meta Ads Audit" },
            { id: 888, top: "Tools for Meta Ads Audits" },
            { id: 999, top: "Common Issues and Fixes" },
            { id: 1000, top: "Preparing Meta Ads Audit Report" }, // Changed id to 1000 to avoid duplication
          ],
        },
        {
          id: 3,
          title: "Website Audit",
          des: [
            { id: 1010, top: "Introduction to Website Audits" },
            { id: 1111, top: "Technical Audit" },
            { id: 1212, top: "SEO Audit" },
            { id: 1313, top: "Content Audit" }, // Fixed formatting here
            { id: 1414, top: "Performance and Security Audit" },
           
          ],
        },
      ];
      
      const trainer=[{
        id:1,
        name:"Mr. Rupansh Srivastava",
        des:"Mr. Rupansh Srivastava is a skilled Digital Marketing Specialist and Trainer at Skillyards. With more than 5 years of experience and over 100 clients served, he offers practical knowledge and expert guidance.",
        img:rupansh
      }]

      const faqs = [
        {
          question: "What will I learn in this website audit with AI workshop?",
          answer: "In this workshop, you'll learn how to use artificial intelligence tools to audit your website comprehensively. We'll cover how to analyze website performance, improve SEO, and enhance user experience. By the end, you'll be able to identify and fix issues to make your website more effective and user-friendly."
        },
        {
          question: "Do I need any prior knowledge of website audits or AI?",
          answer: "No prior knowledge is needed! This workshop is designed for beginners. We'll start with the basics of website audits in a simple, easy-to-understand way. You'll be guided step-by-step, so everyone can follow along and learn."
        },
        {
          question: "How can housewives benefit from this AI workshop?",
          answer: "Housewives can learn valuable skills to improve their personal blogs or small business websites. This workshop will teach you how to use AI to check your website's performance, enhance its visibility on search engines, and create a better visitor experience. It's a great way to make your online presence more effective and even attract more customers."
        }
      ];

       const [show, setshow] = useState();
        const handleClose = () => setshow(false);
        const showOpen = () => setshow(true);
      
        const hasRun = useRef(false);

        useEffect(() => {
          if (!hasRun.current) {
            showOpen();
            hasRun.current = true; 
          }
        }, []);
      
       
        const [showEnd, setShowEnd] = useState(false);
      
        function openM() {
          setShowEnd(true)
      
        }
 
       
        

      
  return (

   <div>
      
     <Helmet>
     <title>Digital Marketing Training | Learn AI-Powered Marketing</title>
        <meta name="description" content="Master digital marketing with AI integration. Join our workshop to learn SEO, WordPress, Shopify, and social media marketing from industry experts." />
        <meta name="keywords" content="digital marketing, AI marketing, SEO training, WordPress development, social media marketing" />
        {/* Preload critical assets */}
      
     </Helmet>
     <div className={`${fadeOut ? "opacity-0" : "opacity-100"} transition-opacity duration-500 ease-out`}> 
    



    {/* heroSection */}
   
<Suspense fallback={<p>...loading</p>} >
  <HeroSection/>
</Suspense>


{/* integrateAiWithDigitalMarketing */}
<div className="mt-[330px] sm:mt-[100px] lg:mt-0">
  <section className="bg-[#414047] py-16 md:py-20 lg:py-20 ">
    <div className="max-w-7xl mx-auto px-6 sm:px-8">
      <header className="text-center mb-16 md:mb-20">
        <div className="flex flex-col gap-2 text-center">
          <h1 className="font-montserrat text-4xl sm:text-5xl md:text-6xl lg:text-5xl text-white font-bold tracking-wide leading-tight">
            Integrate AI with Digital Marketing
          </h1>
          <p className="text-lg sm:text-xl md:text-2xl lg:text-xl leading-relaxed font-montserrat text-white/90 mt-4 md:mt-6 text-left w-full">
            In a survey by Foundation AI, more than <span className="font-semibold">87% of marketers</span> use AI in their processes.
            AI isn't a threat to marketers unless you know how to use it. Integrating AI Tools, Charts, and Tactics can turn out to
            be strenuous for digital marketers who are just starting out their journeys.
          </p>
        </div>
      </header>

  

<div className="flex flex-wrap sm:flex-wrap justify-center gap-6 p-0 m-0 md:my-5 lg:my-2">
  {/* Stat Item 1 */}
    <StatItem value="5" backValue="+ Years" label="Highly Experienced Trainers" />

  {/* Stat Item 2 */}
    <StatItem value="100" backValue="+" label="Clients Served by Trainers" />

  {/* Stat Item 3 */}
    <StatItem value="95" backValue="%" label="Campaign Success Rate" />
</div>



    </div>
  </section>
</div>


   
<section className="px-4 sm:px-6 lg:px-8 py-8 sm:py-12 md:py-16">
      <div className="flex flex-col gap-6 sm:gap-8 md:gap-12">
        {/* Title Section */}
        <div className="text-center  flex flex-col justify-center items-center gap-1">
  <h2 className="font-bold text-3xl sm:text-3xl md:text-3xl lg:text-3xl mb-0">
   Benefits You'll Get From Workshop
  </h2>
  <div className="border-[3px] w-[30%] sm:w-[40%] md:w-[30%] lg:w-[20%] border-yellow-400 mt-2" />
</div>

        {/* Cards Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-6 sm:gap-8 lg:gap-y-32 ">
          {cardData.map((elm) => (
            <div 
              key={elm.id} 
              className="flex justify-center items-center"
            >
              <FlipCard data={elm} />
            </div>
          ))}
        </div>
      </div>
    </section>
    
  
  {/* Crousel */}
    <div className="flex flex-col-reverse md:flex-row justify-center items-center gap-5 p-4 md:p-6 mt-4">
 
 <Suspense fallback={<div>...loading</div>} >
  <div className="w-full"  >
  <Carousel images={images}/>
  </div>
  </Suspense>

  
 
</div>
 
  
  {/* Join */}

  <Suspense  fallback={<p>...loading</p>} >
    <Join/>
  </Suspense>


  <div className="flex flex-col my-12 m-1">
  <div className="text-center mt-2 flex flex-col justify-center items-center gap-1">
  <h2 className=" text-3xl sm:text-3xl md:text-3xl lg:text-3xl mb-0">
   What you'll Learn
  </h2>
  <div className="border-[3px] w-[30%] sm:w-[40%] md:w-[30%] lg:w-[20%] border-yellow-400 mt-2" />
</div>


<div className="flex flex-wrap justify-evenly gap-4 mt-5">
  {learnCards.map((elm) => {
    return (
      <div
        key={elm.id}
        className="w-full  md:w-1/3 lg:w-1/4 p-2"
      ><Suspense>
        
        <LearnCard data={elm} />
        </Suspense> 
      </div>
    );
  })}
</div>

</div>

  
{/* trainerSection */}
<div className=" mx-0 px-0 sm:px-6 lg:px-10 py-8 ">
      <div className="text-center mb-8">
        <h2 className="font-bold text-3xl">
          Meet Our Trainer
        </h2>
        <div className="border-[3px] border-yellow-400 w-32 sm:w-40 lg:w-48 mx-auto mt-2" />
      </div>

      <div className="px-2"> 
        {trainer.map((elm) => (
          <div key={elm.id} className="flex justify-center items-center lg:px-12  " >
            <Suspense fallback={<div>...loading</div>} >
            <TrainerCard
              name={elm.name}
              des={elm.des}
              img={elm.img} 
            />
            </Suspense>
          </div>
        ))}
      </div>
    </div>



  {/* whoCanJoin */}
  <div  className="text-center font-montserrat mt-5"  >
  
 <div  className="mt-5" >
<Suspense fallback={<div>...loading</div>} >
  <WhoCanJoin/>
</Suspense>
 </div>
 </div>
  
  {/* syllabusDigitalMarketing */}
  <div className="flex  justify-center items-center p-2 sm:p-4 m-2 sm:m-4 mt-4">
      <div className="relative group">
        {/* Animated glow effect */}
        <div className="absolute -inset-1 bg-gradient-to-r from-amber-300 via-yellow-400 to-amber-500 rounded-lg blur opacity-70 group-hover:opacity-100 transition duration-1000 group-hover:duration-300 animate-pulse"></div>
        
        {/* Button container */}
       <Link to={"/digital-marketing-training-in-agra"} >
       <div className="relative rounded-3xl w-full flex p-0.5 bg-gradient-to-r from-amber-300 via-yellow-400 to-amber-200  overflow-hidden">
          <button className="relative rounded-3xl w-full flex items-center justify-center gap-3 px-8 sm:px-12 py-3 sm:py-4  bg-white/10 backdrop-blur-sm hover:bg-white/20 transition-all duration-300 ease-out hover:scale-[1.02] active:scale-95">
            {/* Animated background gradient */}
            <div className="absolute inset-0 bg-gradient-to-r from-amber-400/80 to-yellow-400/80 opacity-90 transition-opacity duration-300 group-hover:opacity-100 animate-gradient-xy"></div>
            
            {/* Content wrapper */}
            <div className="relative flex items-center gap-3 transform transition-all duration-300 group-hover:translate-x-1">
              <span className="text-lg sm:text-xl font-medium text-gray-900 group-hover:text-gray-800">
                View Syllabus
              </span>
              <FileText className="w-5 h-5 sm:w-6 sm:h-6 text-gray-900 transition-all duration-300 group-hover:rotate-12 group-hover:scale-110" />
            </div>
          </button>
        </div></Link>
      </div>
    </div>



 {/* faqSection */}

 <div  className=" flex justify-center items-center" >
   <div className="w-[90%]"  >
    <Suspense fallback={<div>...loading</div>} >
   <FAQComponent faqs={faqs} />
    </Suspense>
   </div>
 </div>



 </div>
   </div>

  );
};

export default ConnectWithMarket;
