import React, { useState, useEffect } from 'react';
import { User, Mail, Phone, MapPin, MessageSquare, Send, Loader2, CheckCircle2, Copy, Check } from 'lucide-react';
import TypewriterComponent from 'typewriter-effect';
import axios from 'axios';

const WorkshopRegistration = ({bg, heading,textarr,fot }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const api = "https://www.skillyards.com/api/payment/";
   //const api="http://localhost:8000/api/payment/"
  
   const [copied, setCopied] = useState(false);

  const copyPaymentId = async () => {
    await navigator.clipboard.writeText(success);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };


  const careerOptions = [
    "SEO EXPERT",
    "WORDPRESS DEVELOPER",
    "SHOPIFY DEVELOPER",
    "PERFORMANCE MARKETER",
    "SOCIAL MEDIA MARKETER",
  ];

  const initialFormState = {
    name: "",
    email: "",
    mobile: "",
    city: "",
    message: ""
  };

  const [formData, setFormData] = useState(initialFormState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
    setError('');
  };

  const validateForm = () => {
    if (!formData.name.trim()) return "Name is required";
    if (/\d/.test(formData.name)) return "Name should not contain numbers";
    if (!formData.email.trim() || !/\S+@\S+\.\S+/.test(formData.email)) return "Valid email is required";
    if (!formData.mobile.trim() || !/^\+?[1-9]\d{9,11}$/.test(formData.mobile.replace(/\s+/g, ''))) 
      return "Valid phone number is required";
    if (!formData.city.trim()) return "City is required";
    return "";
  };

  const loadRazorpayScript = () => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.onload = () => resolve(true);
      script.onerror = () => {
        resolve(false);
        setError("Failed to load payment gateway. Please check your internet connection.");
      };
      document.body.appendChild(script);
    });
  };

  const handlePayment = async (e) => {
    e.preventDefault();
    
    const validationError = validateForm();
    if (validationError) {
      setError(validationError);
      return;
    }

    setIsSubmitting(true);
    try {
      const scriptLoaded = await loadRazorpayScript();
      if (!scriptLoaded) {
        throw new Error("Razorpay SDK failed to load");
      }

      const orderResponse = await axios.post(
        `${api}create-razorpay-order`,
        formData, 
        {
          headers: {
            "Content-Type": "application/json"
          },
        }
      );
      
      if (!orderResponse.data?.order) {
        throw new Error("Failed to create order");
      }

      const { amount, id } = orderResponse.data.order;
      const { _id } = orderResponse.data.user;

      const options = {
        key: "rzp_live_Y8Pe5kKuBA8pNN",
        amount: amount.toString(),
        currency: "INR",
        name: "Skillyards Versatility",
        description: "Workshop Registration Payment",
        image: "/Web_logo/front_weblogo.jpeg",
        order_id: id,
        handler: async (response) => {
          try {
            const paymentResult = await axios.post(`${api}verify-payment`, {
              orderData: {
                order_creation_id: id,
                userId: _id,
                razorpay_payment_id: response.razorpay_payment_id,
                razorpay_order_id: response.razorpay_order_id,
                razorpay_signature: response.razorpay_signature,
                amount:99,
                currency: "INR"
              },
              header: {
                "Content-type": "application/json"
              }
            });

            // console.log(paymentResult,"pay");
            
             
            
            if (paymentResult.data?.success) {
              setFormData(initialFormState);
              setSuccess(paymentResult.data?.paymentId);
              setError('');
            }
          } catch (error) {
            setError("Payment verification failed. Please contact support.");
          }
        },
        prefill: {
          name: formData.name,
          email: formData.email,
          contact: formData.mobile,
        },
        notes: {
          address: formData.city,
        },
        theme: {
          color: "#FFB93F",
        }
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    } catch (error) {
      console.log(error,"error while paymentObject");
      
      setError(error.message || "Something went wrong. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  

  return (
    <div className= {`min-h-screen ${bg?"bg-gradient-to-br from-slate-200 to-gray-900":"bg-white"} py-12 lg:px-12   `} >
  <div className="mx-2 w-full">
    <div className="flex flex-col lg:flex-row gap-8 items-center">
      <div className="lg:w-1/2 w-full space-y-3 text-center lg:text-center">
        <h2 className="font-montserrat text-3xl md:text-4xl lg:text-5xl font-bold text-gray-900 transition-all duration-300 ease-in-out">
          {`${heading?`${heading}`:"Who Can Join This ?"}`}
        </h2>

        <div className="h-20 pt-0 flex items-center justify-center mx-auto">
  <div className="flex items-center justify-center text-2xl text-center md:text-3xl lg:text-4xl font-bold text-amber-400">
    <TypewriterComponent
      options={{
        strings:textarr|| careerOptions,
        autoStart: true,
        loop: true,
        delay: 75,
      }}
    />
  </div>
</div>


<p className={`text-lg ${!fot?"text-black":"text-white"} text-center`}>
  {!fot?"Join our comprehensive workshop designed for individuals from all walks of life. Transform your skills and unlock new opportunities": `${fot}`}
</p>


      </div>

      <div
        className={`w-full lg:w-[50%] rounded-2xl shadow-xl hover:shadow-2xl transition-shadow duration-300`}
      >
        {/* Error Section */}
        {error && (
          <div className="mb-4 p-3 bg-red-50 text-red-600 rounded-lg">{error}</div>
        )}

        {/* Success Section */}
        {success ? (
            <div className="text-center space-y-6">
            <CheckCircle2 size={48} className="mx-auto text-green-500" />
            <h3 className="text-2xl font-bold text-gray-900">Payment Successful!</h3>
            
            <div className="flex flex-col items-center gap-4">
              <p className="text-gray-600">Thank you for registering for our workshop.</p>
              
              <div className="bg-gray-50 border border-gray-200 rounded-lg p-4 w-full max-w-md">
                <div className="text-sm text-gray-500 mb-2">Payment ID</div>
                <div className="flex items-center justify-between gap-2 bg-white border border-gray-200 rounded-md p-3">
                  <span className="font-mono text-gray-700">{success}</span>
                  <button 
                    onClick={copyPaymentId}
                    className={`flex items-center gap-2 px-3 py-1.5 rounded-md transition-all duration-200 ${
                      copied 
                        ? 'bg-green-50 text-green-600 border border-green-200' 
                        : 'bg-gray-50 text-gray-600 border border-gray-200 hover:bg-gray-100'
                    }`}
                    aria-label="Copy payment ID"
                  >
                    {copied ? (
                      <>
                        <Check className="w-4 h-4" />
                        <span className="text-sm font-medium">Copied!</span>
                      </>
                    ) : (
                      <>
                        <Copy className="w-4 h-4" />
                        <span className="text-sm font-medium">Copy</span>
                      </>
                    )}
                  </button>
                </div>
              </div>
      
              <p className="text-gray-600">Our team will contact you shortly with further details.</p>
            </div>
          </div>
        ) : (
          // Form Section
          <div className="relative group">
            {/* Animated border background */}
            <div className="absolute -inset-0.5 bg-gradient-to-tr from-cyan-300 to-cyan-600 rounded-xl blur opacity-30 group-hover:opacity-50 transition duration-1000 group-hover:duration-200 animate-gradient-x"></div>

            {/* Main form */}
            <form
              onSubmit={handlePayment}
              className="relative bg-white m-0 p-4 rounded-lg space-y-6"
            >
              <div className="grid md:grid-cols-2 gap-6">
                {/* Name Field */}
                <div className="space-y-2">
                  <label className="flex items-center gap-2 text-sm font-medium text-gray-700">
                    <User size={18} className="text-amber-500" />
                    Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-amber-500 focus:border-transparent transition duration-200"
                    placeholder="Your full name"
                  />
                </div>

                {/* Email Field */}
                <div className="space-y-2">
                  <label className="flex items-center gap-2 text-sm font-medium text-gray-700">
                    <Mail size={18} className="text-amber-500" />
                    Email
                  </label>
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-amber-500 focus:border-transparent transition duration-200"
                    placeholder="example@gmail.com"
                  />
                </div>

                {/* Phone Field */}
                <div className="space-y-2">
                  <label className="flex items-center gap-2 text-sm font-medium text-gray-700">
                    <Phone size={18} className="text-amber-500" />
                    Phone
                  </label>
                  <input
                    type="tel"
                    name="mobile"
                    value={formData.mobile}
                    onChange={handleChange}
                    className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-amber-500 focus:border-transparent transition duration-200"
                    placeholder="+91 8650003501"
                  />
                </div>

                {/* City Field */}
                <div className="space-y-2">
                  <label className="flex items-center gap-2 text-sm font-medium text-gray-700">
                    <MapPin size={18} className="text-amber-500" />
                    City
                  </label>
                  <input
                    type="text"
                    name="city"
                    value={formData.city}
                    onChange={handleChange}
                    className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-amber-500 focus:border-transparent transition duration-200"
                    placeholder="Your city"
                  />
                </div>
              </div>

              {/* Message Field */}
              <div className="space-y-2">
                <label className="flex items-center gap-2 text-sm font-medium text-gray-700">
                  <MessageSquare size={18} className="text-amber-500" />
                  Message (Optional)
                </label>
                <textarea
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  rows="4"
                  className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-amber-500 focus:border-transparent transition duration-200 resize-y min-h-[100px]"
                  placeholder="Any questions or special requirements?"
                />
              </div>

              {/* Submit Button */}
              <div className="relative group">
                <div className="absolute -inset-0.5 bg-gradient-to-r from-sky-400 via-blue-200 to-sky-600 rounded-lg blur opacity-60 group-hover:opacity-100 transition duration-1000 group-hover:duration-200 animate-gradient-x"></div>

                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="relative opacity-80 w-full bg-yellow-400 text-white py-3 px-6 rounded-lg flex items-center justify-center gap-2 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  {isSubmitting ? (
                    <>
                      <Loader2 className="animate-spin" size={20} />
                      Processing...
                    </>
                  ) : (
                    <>
                      <Send size={20} />
                      Join the Workshop!!
                    </>
                  )}
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  </div>
</div>

  );
};

export default WorkshopRegistration;