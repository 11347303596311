import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { Clock, Book, Timer, BookOpen, GraduationCap,Calendar} from "lucide-react";

const TopicDetails = () => {
  const api = "https://skillyards.com/api/admin/";
  const { subject } = useParams();
  const [specificContent, setSpecificContent] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const getAllSpecificContent = async () => {
    try {
      setLoading(true);
      let response = await fetch(`${api}courses/${subject}`);
      response = await response.json();
      setSpecificContent(response?.contents);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleContentClick = (title) => {
    const encodedTitle = encodeURIComponent(title);
    navigate(`/content/${encodedTitle}`);
  };

  useEffect(() => {
    getAllSpecificContent();
  }, [subject]);

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 py-8 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        {/* Header Section */}
        <div className="mb-8">
          <h1 className="text-3xl font-bold text-gray-900 mb-2">
            <BookOpen className="inline-block mr-2 text-blue-600" size={32} />
            {`${subject} Courses`}
          </h1>
          <p className="text-gray-600">Explore comprehensive lessons and materials in {subject}</p>
        </div>

        {/* Course Grid */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          {specificContent?.map((content) => (
            <div
              key={content._id}
              onClick={() => handleContentClick(content.title)}
              className="bg-white rounded-lg shadow-md hover:shadow-lg transition-all duration-300 overflow-hidden cursor-pointer"
            >
              <div className="flex flex-col md:flex-row h-full">
                {/* Image Section */}
                <div className="md:w-2/5 h-48 md:h-auto relative overflow-hidden">
                  <img
                    className="w-full h-full object-cover transition-transform duration-300 hover:scale-105"
                    src={content.file.fileUrl}
                    alt={content.title}
                  />
                  <div className="absolute top-0 right-0 bg-blue-600 text-white px-3 py-1 text-sm font-medium rounded-bl-lg">
                    {content.level}
                  </div>
                </div>

                {/* Content Section */}
                <div className="md:w-3/5 p-6 flex flex-col justify-between">
                  {/* Upper Content */}
                  <div>
                    <div className="flex items-center justify-between text-sm text-gray-600 mb-3">
                      <div className="flex items-center">
                        <Calendar size={16} className="mr-1" />
                        {new Date(content?.createdAt).toLocaleDateString("en-us", {
                          day: "numeric",
                          month: "long",
                        })}
                      </div>
                      <span className="flex items-center">
                        <GraduationCap size={16} className="mr-1" />
                        {content?.authorname}
                      </span>
                    </div>

                    <h2 className="text-xl font-semibold text-gray-900 mb-3 line-clamp-2 hover:text-blue-600">
                      {content.title}
                    </h2>

                    <div
                      className="text-gray-600 text-sm line-clamp-2 mb-4"
                      dangerouslySetInnerHTML={{
                        __html: content.description.slice(0, 250) +
                          (content.description.length > 150 ? "..." : ""),
                      }}
                    />
                  </div>

                  {/* Footer */}
                  <div className="flex items-center justify-between mt-auto">
                    <div className="flex items-center text-blue-600">
                      <Timer size={16} className="mr-1" />
                      <span className="text-sm font-medium">{content.duration}</span>
                    </div>
                    <button className="text-sm text-blue-600 hover:text-blue-800 font-medium flex items-center">
                      Learn More
                      <Book size={16} className="ml-1" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
         
    </div>
  );
};

export default TopicDetails;