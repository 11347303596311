
export default function SkillyardsLoading() {
  return (
    <div className="h-[100vh] w-[100vw] bg-slate-100 flex flex-col justify-center items-center">
      {/* Logo */}
      <div className="w-[40%] sm:w-[30%] md:w-[20%] h-[80px]">
        <img
          src="../Web_logo/front_weblogo.webp"  // Use the correct path or import image if using Next.js
          alt="Skillyards Logo"
          layout="responsive"
          width={500}  // Set an appropriate width
          height={100}  // Set an appropriate height
          className="object-contain"
          loading="lazy"  // Lazy loading image
        />
      </div>

      {/* Loading Bar */}
      <div className="w-[80%] sm:w-[60%] md:w-[50%] h-2 bg-yellow-500 relative overflow-hidden mt-4 rounded-lg">
        <div className="absolute h-full w-[50%] bg-black animate-moveBar"></div>
      </div>
    </div>
  );
}
