// import React from "react";
// import DigitalMarketing from './Program/DigitalMarketing';

// const LoadingSpiner = () => {
//   return (
//     <div className="bg-gray-900 h-[100vh] w-full flex justify-center items-center p-6 ">
//       <div className="text-gray-400 font-poppins font-medium text-xl flex items-center  gap-3 justify-center h-40 p-4 rounded-lg">
//         <p className="text-6xl" >loading...</p>
//         <div className="overflow-hidden relative h-[30px] text-2xl px-3 ">
//           <div className="absolute inset-0 bg-gradient-to-r from-gray-900 via-transparent to-gray-900 z-10"></div>
//           <span className="block h-10px px-1 text-yellow-300 animate-spin-words">Full-Stack</span>
//           <span className="block h-10px px-1 text-pink-300 animate-spin-words">UI/UX</span>
//           <span className="block h-10px px-1 text-blue-400 animate-spin-words">Degree</span>
//           <span className="block h-10px px-1 text-cyan-300 animate-spin-words">CyberSecurity</span>
//           <span className="block h-10px px-1 text-blue-300 animate-spin-words">DigitalMarketing</span>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default LoadingSpiner;




import React from 'react';

const LoadingSpiner = () => {
  return (
    <div className="bg-gray-900 min-h-screen  h-[100vh] w-full flex justify-center items-center p-4 md:p-6">
      <div className="text-gray-400 font-poppins font-medium text-center flex  items-center gap-3 justify-center h-auto md:h-40 p-4 rounded-lg">
        <p className="text-3xl md:text-6xl">loading...</p>
        <div className="overflow-hidden relative h-[40px] md:h-[30px] text-xl md:text-2xl px-3">
          <div className="absolute inset-0 bg-gradient-to-r from-gray-900 via-transparent to-gray-900 z-10"></div>
          <span className="block h-10 px-1 text-yellow-300 animate-spin-words">Full-Stack</span>
          <span className="block h-10 px-1 text-pink-300 animate-spin-words">UI/UX</span>
          <span className="block h-10 px-1 text-blue-400 animate-spin-words">Degree</span>
          <span className="block h-10 px-1 text-cyan-300 animate-spin-words">CyberSecurity</span>
          <span className="block h-10 px-1 text-blue-300 animate-spin-words">DigitalMarketing</span>
        </div>
      </div>
    </div>
  );
};

export default LoadingSpiner;
