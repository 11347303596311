const ContentTable = ({ data, handleEdit, handleDelete }) => {
    return (
      <div className="mx-auto p-4">
        <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
          <thead className="bg-gray-800 text-white">
            <tr>
            <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Subject</th>
              <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Title</th>
              <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Description</th>
              <th className="text-left py-3 px-4 uppercase font-semibold text-sm">SubTopic</th>
              <th className="text-left py-3 px-4 uppercase font-semibold text-sm">SubDescription</th>
              <th className="text-left py-3 px-4 uppercase font-semibold text-sm">CodeSnippet</th>
              <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Action</th>
            </tr>
          </thead>
          <tbody className="text-gray-700">
            {data.map((item, index) => (
              <tr key={index} className={`${index % 2 === 0 ? "bg-gray-100" : "bg-white"}`}>
                <td className="text-left py-3 px-4">{item.subject || "N/A"}</td>
                <td className="text-left py-3 px-4">{item.title || "N/A"}</td>
                <td className="text-left py-3 px-4">{item.description || "N/A"}</td>
                <td className="text-left py-3 px-4">{item.subTopic || "N/A"}</td>
                <td className="text-left py-3 px-4">{item.subDescription || "N/A"}</td>
                <td className="text-left py-3 px-4">{item.codeSnippet || "N/A"}</td>
                <td className="text-left py-3 px-4 flex space-x-2">
                  <button
                    onClick={() => handleEdit(item)}
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                    Edit
                  </button>
                  <button
                    onClick={() => handleDelete(item.id)}
                    className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  export default ContentTable;