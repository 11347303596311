


import "@fontsource/roboto";

import React, { useState, useEffect, useMemo } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import LoadingSpinner from "../Loading";
import { Helmet } from "react-helmet";
import SkillyardsLoading from "../SkillyardsLoading";

const SingleBlogPage = () => {
  const { titel } = useParams();
  const [searchParams]=useSearchParams()
  const id=searchParams.get('id')

 
  
  
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const calculateReadingTime = (blog) => {
    // Helper function to count words
    const countWords = (text) => text.split(/\s+/).filter(Boolean).length;
  
    // Count words from main fields
    let totalWordCount = 0;
  
    if (blog?.discription) totalWordCount += countWords(blog?.discription);
    if (blog?.imagediscription) totalWordCount += countWords(blog.imagediscription);
  
    // Count words from content array
    if (blog?.content && Array.isArray(blog.content)) {
      blog.content.forEach((section) => {
        if (section?.discription) totalWordCount += countWords(section.discription);
        if (section?.discription2) totalWordCount += countWords(section.discription2);
      });
    }
  
    // Average reading speed (words per minute)
    const averageReadingSpeed = 200;
  
    // Calculate reading time in minutes
    const readingTime = Math.ceil(totalWordCount / averageReadingSpeed);
  
    return `${readingTime} min read`;
  };


  //  const api = "http://localhost:8000/api/";
  //  const api = "https://www.skillyards.com/blog";
   const api="https://skillyards.com/api/"
  const [readTime,setReadTime]=useState('')

  const fetchBlogData = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${api}user/getSingleblogs/${titel}?id=${id}`);
      if (!response.ok) {
        throw new Error("Failed to fetch blog data");
      }
      const data = await response.json();
      setBlog(data.data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };
  
  const [categoryClass, setCategoryClass] = useState("");
  const categoryColors = {
    "allcategory": "bg-cyan-400 text-gray-900",
    "Digital Marketing": "bg-blue-500 text-white",
    "UI/UX Designing": "bg-green-500 text-white",
    "Full-Stack Web Development": "bg-yellow-500 text-gray-900",
    "Cybersecurity": "bg-red-500 text-white",
    "Degree": "bg-purple-500 text-white",
    "Communication Skills": "bg-orange-500 text-white"
  };
  useEffect(() => {
    fetchBlogData();

  

  }, [titel]);
  useEffect(() => {
    setReadTime(calculateReadingTime(blog));
  
    if (blog?.category) {
     
      const normalizedCategory = blog.category.trim();
      const newClass = categoryColors[normalizedCategory];
      
     
      if (newClass) {
        setCategoryClass(newClass);
      } else {
      
        console.warn(`No color class found for category: ${normalizedCategory}`);
        setCategoryClass(categoryColors["allcategory"]);
      }
    }
  }, [blog]);

 

 

 

 
  
  
  

  if (error) {
    
    return (
      <div className="min-h-screen flex justify-center items-center bg-gray-100">
        <p className="text-red-500 text-lg">{error}</p>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="min-h-screen flex justify-center items-center bg-gray-100">
        <LoadingSpinner />
      
      </div>
    );
  }

  
  

  return (
    <div className="bg-gray-50 min-h-screen py-12 m-auto ">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{blog?.title || "Blog"}</title>
        <meta httpEquiv="x-ua-compatible" content="ie=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="description" content="Skillyards blog details page" />
        <meta name="keywords" content="Skillyards, blog, education" />
        <meta name="author" content="Skillyards" />
      </Helmet>

      <div className="border border-black xl:max-w-[90%] w-full sm:w-100%  px-2 mx-auto md:px-4 sm:px-0 lg:px-8 bg-white shadow-xl rounded-2xl overflow-hidden">
        {/* Header Section */}
        <header className="pt-12 pb-8  text-center border-b-2 ">
          <div className="mx-auto flex flex-col gap-5">
            <h1 className="text-3xl md:text-4xl lg:text-6xl font-semibold text-gray-900 leading-tight tracking-wide">
              {blog?.title}
            </h1>
            <div className="flex justify-center items-center gap-6 ">
              <span className={`text-sm px-3 py-1 rounded-full font-medium ${categoryClass} `}>
                {blog?.category}
              </span>
              <span className="text-md font-medium">By {blog?.authorname}</span>
            <div className="flex justify-end w-[70%]" >
            <span className="bg-blue-100 text-blue-800 text-sm px-3 py-1 rounded-full font-medium">
               {readTime&&readTime}
              </span>
            </div>
            </div>
          </div>
        </header>

        {/* Main Image Section */}
        <div className="my-12 md:px-4">
          <div className="flex flex-col gap-10 mx-auto">
            {blog?.images && (
              <img
                src={blog?.images}
                alt={blog?.imagediscription || "Blog Image"}
                className="w-full h-auto object-cover rounded-2xl shadow-2xl"
              />
            )}
            {blog?.imagediscription && (
              <div
                className="mt-12 text-xl md:text-2xl text-left md:text-center px-4"
                dangerouslySetInnerHTML={{ __html: blog?.imagediscription }}
              />
            )}
          </div>
          <div className="font-opensans text-xl [word-spacing:0.3rem] text-center" >{blog?.discription}</div>
        </div>

        {/* Table of Contents & Supplementary Image */}
        <div className="grid md:grid-cols-2 gap-8 md:px-4 mb-12">
          {/* Table of Contents */}
          {blog?.content?.length > 0 && (
            <div className="bg-yellow-50 border-2 border-yellow-500 rounded-xl md:p-6 space-y-4">
              <h2 className="text-2xl font-bold text-center text-gray-900 mb-4">
                Table of Contents
              </h2>
              {blog?.content?.map((elm, i) => (
                <div
                  key={i}
                  className="flex hover:bg-yellow-100 cursor-pointer p-2 rounded-lg transition"
                >
                  <p className="text-yellow-600 font-bold text-lg">{i + 1}.</p>
                  <p className="text-lg text-gray-800 ml-2">{elm?.tablename}</p>
                </div>
              ))}
            </div>
          )}

          {/* Supplementary Image */}
          {blog?.tableimage && (
            <div className="flex items-center justify-center">
              <img
                src={blog?.tableimage}
                alt="Supplementary Table Image"
                className="max-h-[500px] w-auto object-contain rounded-xl "
              />
            </div>
          )}
        </div>

        {/* Content Sections */}
        <section className="space-y-12 md:px-4 mb-12 ">
          {blog?.content?.map((section) => (
            <article
              key={section?._id}
              className="bg-white md:p-6 p-2  rounded-2xl   "
            >
              <h2 className="text-3xl font-extrabold  text-gray-800 mb-[12]">
                {section?.heading}
              </h2>
              <p
                className="text-gray-800  [word-spacing:0.3rem] text-xl "
                dangerouslySetInnerHTML={{ __html: section?.discription }}
              />
               <p
                className="text-gray-800 [word-spacing:0.3rem] text-xl "
                dangerouslySetInnerHTML={{ __html: section?.discription2 }}
              />
            </article>
          ))}
        </section>
      </div>
    </div>
  );
};

export default SingleBlogPage;
