import React, { useState, useEffect } from "react";
import { CKEditor } from '@ckeditor/ckeditor5-react';
 import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const FormComponent = ({ formData, setFormData }) => {
 // const api = "http://localhost:8000/api/admin/";
 const api="https://skillyards.com/api/admin/"

  const [options, setOptions] = useState([]);

  // Fetch available courses
  const getCourses = async () => {
    try {
      const response = await fetch(`${api}getAllCourses`);
      const data = await response.json();

      if (!data.error) {
        setOptions(data.courseSections);
      }
    } catch (error) {
      console.error("Error fetching courses:", error);
      alert("Failed to fetch course sections. Please try again.");
    }
  };

  useEffect(() => {
    getCourses();
  }, []);

  // Autofill fields based on course selection
  const handleAutofill = (e) => {
    const selectedCourseId = e.target.value;
    const selectedCourse = options.find((course) => course._id === selectedCourseId);
   
      setFormData({
        ...formData,courseId: selectedCourse._id
        
      });
    
  };

  
  


  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle array input changes
  const handleArrayChange = (e, name) => {
    const values = e.target.value.split(",");
    setFormData({ ...formData, [name]: values });
  };

  // Handle file upload
  const handleFileChange = (e) => {
    setFormData({ ...formData, file: e.target.files[0] });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((item) => data.append(key, item));
      } else {
        data.append(key, value);
      }
    });
   

    try {
      const response = await fetch(`${api}uploadCreateContent`, {
        method: "POST",
        body: data,
      });
      const result = await response.json();

      if (!result.error) {
        alert("Content uploaded successfully!");
      } else {
        alert("Failed to upload content.");
      }
    } catch (error) {
      console.error("Error uploading content:", error);
      alert("Error occurred while uploading content.");
    }
  };

  return (
    <form
    onSubmit={handleSubmit}
    className="bg-white shadow-2xl rounded-2xl p-8 w-full space-y-6"
  >
    <h2 className="text-xl text-center text-gray-800 tracking-tight">
      Post Study Material
    </h2>

    {/* Course Section Dropdown */}
    <div>
      <label htmlFor="courseSection" className="block text-gray-700 font-medium mb-1">
        Select Course Section
      </label>
      <select
        id="courseSection"
        name="courseSection"
        onChange={handleAutofill}
        className="w-full border rounded-lg px-4 py-2 mt-1 focus:outline-none focus:ring-2 focus:ring-blue-500"
      >
        <option value="">Select a section</option>
        {options.map((option) => (
          <option key={option._id} value={option._id}>
            {option.subject}
          </option>
        ))}
      </select>
    </div>

    {/* Title */}
    <div>
      <label htmlFor="title" className="block text-gray-700 font-medium mb-1">
        Title
      </label>
      <input
        type="text"
        id="title"
        name="title"
        value={formData.title}
        onChange={handleChange}
        className="w-full border rounded-lg px-4 py-2 mt-1 focus:outline-none focus:ring-2 focus:ring-blue-500"
        placeholder="Enter title"
      />
    </div>

    {/* Topic */}
    <div>
      <label htmlFor="topic" className="block text-gray-700 font-medium mb-1">
        Topic
      </label>
      <input
        type="text"
        id="topic"
        name="topic"
        value={formData.topic}
        onChange={handleChange}
        className="w-full border rounded-lg px-4 py-2 mt-1 focus:outline-none focus:ring-2 focus:ring-blue-500"
        placeholder="Enter topic"
      />
    </div>

    {/* Description with CKEditor */}
    <div>
      <label htmlFor="description" className="block text-gray-700 font-medium mb-1">
        Description
      </label>
      <CKEditor
        editor={ClassicEditor}
        data={formData.description || ""}
        onChange={(event, editor) => {
          const data = editor.getData();
          setFormData({ ...formData, description: data });
        }}
      />
    </div>

    {/* SubTopic */}
    <div>
      <label htmlFor="subTopic" className="block text-gray-700 font-medium mb-1">
        SubTopic
      </label>
      <input
        type="text"
        id="subTopic"
        name="subTopic"
        value={formData.subTopic}
        onChange={handleChange}
        className="w-full border rounded-lg px-4 py-2 mt-1 focus:outline-none focus:ring-2 focus:ring-blue-500"
        placeholder="Enter subtopic"
      />
    </div>

    {/* SubDescription with CKEditor */}
    <div>
      <label htmlFor="subDescription" className="block text-gray-700 font-medium mb-1">
        SubDescription
      </label>
      <CKEditor
        editor={ClassicEditor}
        data={formData.subDescription || ""}
        onChange={(event, editor) => {
          const data = editor.getData();
          setFormData({ ...formData, subDescription: data });
        }}
      />
    </div>

    {/* Code Snippet */}
    <div>
      <label htmlFor="codeSnippet" className="block text-gray-700 font-medium mb-1">
        Code Snippet
      </label>
      <textarea
        id="codeSnippet"
        name="codeSnippet"
        value={formData.codeSnippet}
        onChange={handleChange}
        rows="3"
        className="w-full border rounded-lg px-4 py-2 mt-1 focus:outline-none focus:ring-2 focus:ring-blue-500"
        placeholder="Enter code snippet"
      ></textarea>
    </div>

    {/* Links */}
    <div>
      <label htmlFor="links" className="block text-gray-700 font-medium mb-1">
        Links (comma-separated)
      </label>
      <input
        type="text"
        id="links"
        name="links"
        value={formData.links}
        onChange={(e) => handleArrayChange(e, "links")}
        className="w-full border rounded-lg px-4 py-2 mt-1 focus:outline-none focus:ring-2 focus:ring-blue-500"
        placeholder="Enter links"
      />
    </div>

    {/* File Upload */}
    <div>
      <label htmlFor="file" className="block text-gray-700 font-medium mb-1">
        Upload File
      </label>
      <input
        type="file"
        id="file"
        name="file"
        onChange={handleFileChange}
        className="w-full border rounded-lg px-4 py-2 mt-1 focus:outline-none focus:ring-2 focus:ring-blue-500"
      />
    </div>

    {/* Remaining Fields */}
    {[
      { id: "example", label: "Example", type: "textarea" },
      { id: "level", label: "Level", type: "text" },
      { id: "duration", label: "Duration", type: "text" },
      { id: "tags", label: "Tags (comma-separated)", type: "text" },
      { id: "prerequisites", label: "Prerequisites (comma-separated)", type: "text" },
    ].map(({ id, label, type }) => (
      <div key={id}>
        <label htmlFor={id} className="block text-gray-700 font-medium mb-1">
          {label}
        </label>
        {type === "textarea" ? (
          <textarea
            id={id}
            name={id}
            value={formData[id]}
            onChange={handleChange}
            rows="3"
            className="w-full border rounded-lg px-4 py-2 mt-1 focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder={`Enter ${label.toLowerCase()}`}
          ></textarea>
        ) : (
          <input
            type={type}
            id={id}
            name={id}
            value={formData[id]}
            onChange={(e) =>
              id === "tags" || id === "prerequisites"
                ? handleArrayChange(e, id)
                : handleChange(e)
            }
            className="w-full border rounded-lg px-4 py-2 mt-1 focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder={`Enter ${label.toLowerCase()}`}
          />
        )}
      </div>
    ))}

    {/* Submit Button */}
    <button
      type="submit"
      className="w-full bg-gradient-to-r from-blue-500 to-blue-600 text-white rounded-lg py-3 font-semibold hover:from-blue-600 hover:to-blue-700 transition duration-300"
    >
      Save Content
    </button>
  </form>
  );
};

export default FormComponent;
